<template>
  <div id="app">
    <h1>To-Do List</h1>
    <div v-if="signedIn">Signed in as {{ activeUserEmail }}</div>
    <div class="sign-out">
      <amplify-sign-out v-if="signedIn"></amplify-sign-out>
    </div>
    <div class="auth">
      <amplify-authenticator username-alias="email"
        ><amplify-sign-up
          slot="sign-up"
          header-text="Sign Up for a Todo account:"
          username-alias="email"
          :form-fields.prop="formFields"
        ></amplify-sign-up>
        <amplify-sign-in
          slot="sign-in"
          username-alias="email"
        ></amplify-sign-in>
      </amplify-authenticator>
    </div>
    <template v-if="signedIn">
      <drop-down-selector
        @selection-changed="sourceChanged"
        options="internal,local,raw s3,sms,aws lambda,rds js"
        :default="DataSource"
      />
      <to-do-form @todo-added="addToDo"></to-do-form>
      <h2 id="list-summary" ref="listSummary" tabindex="-1">
        {{ listSummary }}
      </h2>
      <ul aria-labelledby="list-summary" class="stack-large">
        <li v-for="item in ToDoItems" :key="item.id">
          <to-do-item
            :label="item.label"
            :done="item.done"
            :id="item.id"
            @checkbox-changed="updateDoneStatus(item.id)"
            @item-deleted="deleteToDo(item.id)"
            @item-edited="editToDo(item.id, $event)"
          >
          </to-do-item>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import ToDoItem from "./components/ToDoItem.vue";
import ToDoForm from "./components/ToDoForm";
import DropDownSelector from "./components/DropDownSelector";
import uniqueId from "lodash.uniqueid";
//import auth from "./components/Auth";

import { Amplify, Auth, Hub } from "aws-amplify";
import config from "./aws-exports";

///local storage keys
const localStorageDataSource = "selectedDataSource";

console.log("App.vue", config);
Amplify.configure(config);
Auth.configure(config);

export default {
  name: "app",
  components: {
    ToDoItem,
    ToDoForm,
    DropDownSelector,
    //auth,
  },
  data() {
    return {
      signedIn: false,
      activeUser: null,
      ToDoItems: [],
      InternalToDoItems: [
        { id: uniqueId("todo-"), label: "Learn Vue", done: false },
        {
          id: uniqueId("todo-"),
          label: "Create a Vue project with the CLI",
          done: true,
        },
        { id: uniqueId("todo-"), label: "Have fun", done: true },
        { id: uniqueId("todo-"), label: "Create a to-do list", done: false },
      ],
      DataSource: "0", //index of the selected data source
      formFields: [
        //customize the fields that appear
        //and add a name field with a label & placeholder
        //{ type: "name", label: "Name *", placeholder: "Enter your full name" },
        { type: "email" },
        { type: "password" },
      ],
    };
  },
  beforeCreate() {
    console.log("before create");
    Hub.listen("auth", async (data) => {
      console.log("data:", data);
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
        console.log("user is signed in!");
        this.activeUser = payload.data;
        console.log("activeuser", this.activeUser);
        //this.$router.push("/profile");
        await this.fetchToDoData();
      }
      if (payload.event === "signOut") {
        //this.$router.push("/auth");
        console.log("user is signed out!");
        this.activeUser = null;
        this.signedIn = false;
        this.todoItems = [];
      }
      if (payload.event === "configured") {
        console.log("auth has been configured");
      }
    });

    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        this.signedIn = true;
        console.log("user already signed in!", user);
        this.activeUser = user;
        await this.fetchToDoData();
      })
      .catch(() => (this.signedIn = false));
  },
  //before the app is mounted, fetch any pre-set data
  //from local storage
  created() {
    const storedDataSource = localStorage.getItem(localStorageDataSource);
    if (storedDataSource !== null) {
      console.log("Using last set datasource", storedDataSource);
      this.DataSource = storedDataSource;
    }
  },
  async mounted() {
    await this.fetchToDoData();
    /*
    try {
      console.log("fetching from rds");
      const response = await fetch(
        //"https://xjzexu7b3a.execute-api.us-west-2.amazonaws.com/test",
        "https://2yvoxh1w2k.execute-api.us-west-2.amazonaws.com/test",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("fetch completed");
      if (response.ok) {
        const json = await response.json();
        console.log("From RDS", json);

        if (json.results !== undefined) {
          //we have an array from RDS of results
          const data = json.results;
          const todoItems = [];
          for (const item of data) {
            todoItems.push({
              id: item.Xid?.toString() ?? uniqueId("no-id"),
              label: item.description,
              done: item.completed != 0,
            });
          }
          console.log(todoItems);
        }
      } else {
        console.log("error fetching from RDS");
      }
    } catch (error) {
      console.log("throw", error);
    }*/
  },
  methods: {
    //triggered when the source for the list is changed
    //will force the data to be updated
    async sourceChanged(updatedSource) {
      console.log("SourceChanged!", updatedSource);
      this.DataSource = updatedSource.toString();
      localStorage.setItem(localStorageDataSource, updatedSource);
      await this.fetchToDoData();
    },
    //fetch the todo list from an external resource
    //uses currentSource as the source url
    async fetchToDoData() {
      try {
        var source = this.currentSource;
        if (source === null) {
          this.ToDoItems = this.InternalToDoItems;
          return;
        }
        console.log("source", source);
        if (this.DataSource == "5") {
          //we have an RDS endpoint, so append the user id??
          if (this.activeUserEmail == "") {
            console.log("user email is blank, so do nothing yet");
            this.todoItems = [];
            return;
          }
          source += `/${this.activeUserEmail}`;
          console.log("updated source", source);
        }

        const response = await fetch(source, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const myJson = await response.json();
          //check to see if we're pulling from a RDS endpoint
          //as we'll need to extract and convert the results
          if (this.DataSource == "5") {
            //we pulled from the RDS endpoint, so we need to massage the json
            console.log("From RDS", myJson);
            if (myJson.results !== undefined) {
              //we have an array from RDS of results
              const data = myJson.results;
              const todoItems = [];
              for (const item of data) {
                todoItems.push({
                  id: item.id?.toString() ?? uniqueId(),
                  label: item.description,
                  done: item.completed != 0,
                });
              }
              console.log(todoItems);
              this.ToDoItems = todoItems;
            }
          } else {
            this.ToDoItems = myJson;
          }
        } else {
          console.log("error loading source!");
        }
      } catch (err) {
        console.log("Error fetching data", err);
      }
    },
    async addToDo(toDoLabel) {
      this.ToDoItems.push({
        id: uniqueId("todo-"),
        label: toDoLabel,
        done: false,
      });
      //push the new data to the RDS endpoint if it's selected
      if (this.DataSource == "5") {
        try {
          const result = await fetch(
            `${this.currentSource}/${this.activeUserEmail}`,
            {
              method: "POST",
              body: JSON.stringify({ description: toDoLabel }),
            }
          );
          if (result.ok) {
            console.log("Posted new item");
          } else {
            console.log("unable to post new item!");
          }
        } catch (error) {
          console.log("Post error:", error);
        }
      }
    },
    updateDoneStatus(toDoId) {
      const toDoToUpdate = this.ToDoItems.find((item) => item.id === toDoId);
      toDoToUpdate.done = !toDoToUpdate.done;
    },
    deleteToDo(toDoId) {
      const itemIndex = this.ToDoItems.findIndex((item) => item.id === toDoId);
      this.ToDoItems.splice(itemIndex, 1);
      this.$refs.listSummary.focus();
    },
    editToDo(toDoId, newLabel) {
      const toDoToEdit = this.ToDoItems.find((item) => item.id === toDoId);
      toDoToEdit.label = newLabel;
    },
  },
  computed: {
    activeUserEmail() {
      const email = this.activeUser?.attributes?.email ?? "";
      console.log("User's email is", email);
      return email;
    },
    listSummary() {
      const numberFinishedItems = this.ToDoItems.filter((item) => item.done)
        .length;
      return `${numberFinishedItems} out of ${this.ToDoItems.length} items completed`;
    },
    //get the current source URL based on current index
    currentSource() {
      switch (this.DataSource) {
        case "1":
          return "test-data.json";
        case "2":
          return "https://s3-us-west-2.amazonaws.com/todo.qwiktrax.com/aws-test-data.json";
        case "3":
          return "https://stalltrax.silvermustangstudios.com/test-data.json";
        case "4":
          return "https://xjzexu7b3a.execute-api.us-west-2.amazonaws.com/test";
        case "5":
          return "https://2yvoxh1w2k.execute-api.us-west-2.amazonaws.com/test/todos"; //rds endpoint
        default:
          return null;
      }
    },
  },
};
</script>


<style>
/* Global styles */
.btn {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}
.btn__danger {
  color: #fff;
  background-color: #ca3c3c;
  border-color: #bd2130;
}
.btn__filter {
  border-color: lightgrey;
}
.btn__danger:focus {
  outline-color: #c82333;
}
.btn__primary {
  color: #fff;
  background-color: #000;
}
.btn-group {
  display: flex;
  justify-content: space-between;
}
.btn-group > * {
  flex: 1 1 auto;
}
.btn-group > * + * {
  margin-left: 0.8rem;
}
.label-wrapper {
  margin: 0;
  flex: 0 0 100%;
  text-align: center;
}
[class*="__lg"] {
  display: inline-block;
  width: 100%;
  font-size: 1.9rem;
}
[class*="__lg"]:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 620px) {
  [class*="__lg"] {
    font-size: 2.4rem;
  }
}
.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}
[class*="stack"] > * {
  margin-top: 0;
  margin-bottom: 0;
}
.stack-small > * + * {
  margin-top: 1.25rem;
}
.stack-large > * + * {
  margin-top: 2.5rem;
}
@media screen and (min-width: 550px) {
  .stack-small > * + * {
    margin-top: 1.4rem;
  }
  .stack-large > * + * {
    margin-top: 2.8rem;
  }
}
/* End global styles */
#app {
  background: #fff;
  margin: 2rem 0 4rem 0;
  padding: 1rem;
  padding-top: 0;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2.5rem 5rem 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 550px) {
  #app {
    padding: 4rem;
  }
}
#app > * {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
#app > form {
  max-width: 100%;
}
#app h1 {
  display: block;
  min-width: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}
</style>
