<template>
  <div class="drop-down-selector">
    <label>Data Source:</label>
    <select
      v-model="selectedOption"
      name="dataSource"
      id="dataSource"
      @change="onOptionChanged($event)"
    >
      <option
        v-for="option in optionsList"
        :key="option.id"
        :value="optionsList.indexOf(option)"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: { options: { required: true, type: String }, default: String },
  methods: {
    onOptionChanged() {
      //console.log("option changed", this.optionsList[this.selectedOption]);
      this.$emit("selection-changed", this.selectedOption);
    },
  },
  data() {
    return {
      selectedOption: this.default,
    };
  },
  computed: {
    optionsList() {
      return this.options.split(",");
    },
  },
};
</script>