import Vue from 'vue'
import App from './App.vue'
import { Amplify, Auth } from 'aws-amplify'

import '@aws-amplify/ui-vue'
import config from './aws-exports';

import './assets/reset.css';

//console.log(config)
Amplify.configure(config)
Auth.configure(config);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
