/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:43692a8d-6fce-4eff-9909-8627cb30ff46",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xA7pXc8L9",
    "aws_user_pools_web_client_id": "6k8lgclmvvsa08sljhb72v0j98",
    "oauth": {}
};


export default awsmobile;
